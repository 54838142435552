import * as z from "zod";

const errorMapping = {
    first_name_empty: "checkout.first_name_empty",
    last_name_empty: "checkout.last_name_empty",
    address_empty2: "checkout.address_empty2",
    zip_code_empty: "checkout.zip_code_empty",
    zip_code_invalid: "checkout.zip_code_invalid",
    province_empty: "checkout.province_empty",
    district_empty: "checkout.district_empty",
    mobile_empty: "checkout.mobile_empty",
    mobile_invalid: "checkout.mobile_invalid",
    email_empty: "login.email_empty",
    email_invalid: "login.email_invalid",
};

export const addressValidatorSchema = z.object({
    first_name: z
        .string({
            required_error: errorMapping.first_name_empty,
        })
        .min(1, { message: errorMapping.first_name_empty }),
    last_name: z
        .string({
            required_error: errorMapping.last_name_empty,
        })
        .min(1, { message: errorMapping.last_name_empty }),
    address: z
        .string({
            required_error: errorMapping.address_empty2,
        })
        .min(1, {
            message: errorMapping.address_empty2,
        }),
    zip_code: z
        .string({
            required_error: errorMapping.zip_code_empty,
            invalid_type_error: errorMapping.zip_code_invalid,
        })
        .min(1, {
            message: errorMapping.zip_code_empty,
        })
        .regex(/^[0-9]{5}$/, { message: errorMapping.zip_code_invalid }),
    province: z
        .string({
            required_error: errorMapping.province_empty,
        })
        .min(1, {
            message: errorMapping.province_empty,
        }),
    district: z
        .string({
            required_error: errorMapping.district_empty,
        })
        .min(1, {
            message: errorMapping.district_empty,
        }),
    mobile: z
        .string({
            required_error: errorMapping.mobile_empty,
        })
        .min(1, {
            message: errorMapping.mobile_empty,
        })
        .regex(/^0[0-9]{9}$/, {
            message: errorMapping.mobile_invalid,
        }),
    country: z.string().min(1),
    email: z
        .string({
            required_error: errorMapping.email_empty,
        })
        .min(1, {
            message: errorMapping.email_empty,
        })
        .regex(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, {
            message: errorMapping.email_invalid,
        }),
    is_default: z.boolean().optional(),
    stateId: z.string().min(1),
});

export const editprofileValidatorSchema = z
    .object({
        nickname: z.string(),
        fb: z.string(),
        lineid: z.string(),
        introduce: z.string(),
        bankid: z.string(),
        bank_account_no: z.string(),
        bank_account_no_confirm: z.string(),
        first_name: z.string(),
        last_name: z.string(),
    })
    .refine((data) => data.bank_account_no === data.bank_account_no_confirm, {
        message: "bank account not match",
        path: ["bank_account_no_confirm"], // path of error
    });

export type AddressValidatorSchema = z.infer<typeof addressValidatorSchema>;
export type EditProfileValidatorSchema = z.infer<typeof editprofileValidatorSchema>;
