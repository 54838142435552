"use client";

import * as React from "react";

const fetchSvg = async (url: string) => {
    try {
        const response = await fetch(url, {
            headers: {
                "Cache-Control": "no-cache, no-store, must-revalidate, post-check=0, pre-check=0",
                // eslint-disable-next-line quote-props
                Pragma: "no-cache",
                // eslint-disable-next-line quote-props
                Expires: "0",
            },
        });

        const text = await response.text();
        // Remove the XML declaration
        const svg = text.replace(/<\?xml.*\?>/, "");
        return svg;
    } catch (error) {
        console.error("Fail to fetch SVG", error);
        return "";
    }
};

// Fetches an SVG from the file and renders it inline
// This is useful so that SVG values like 'currentColor' work properly with local styling
type Props = {
    src: string;
    className?: string;
    wrapperClassName?: string;
};

const RemoteSVG = React.memo(({ src, className = "", wrapperClassName = "" }: Props) => {
    const id = React.useId();

    React.useEffect(() => {
        fetchSvg(src)
            .then((svg) => {
                const element = document.getElementById(id);

                if (element) {
                    element.innerHTML = svg;

                    if (className) {
                        element.querySelector("svg")?.classList.add(...className.split(" "));
                    }
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }, [className, id, src]);

    return <div id={id} className={wrapperClassName} />;
});

RemoteSVG.displayName = "RemoteSVG";

export default RemoteSVG;
