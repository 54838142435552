import { cn } from "@/lib/utils";
import { CSSProperties } from "react";

const MaxWidthWrapper = ({
    className,
    style,
    children,
    disabledPadding,
    sectionWidth = "CONTAINER",
    contentWidth = "CONTAINER",
}: {
    className?: string;
    children: React.ReactNode;
    style?: CSSProperties;
    disabledPadding?: boolean;
    sectionWidth?: "CONTAINER" | "FLUID";
    contentWidth?: "CONTAINER" | "FLUID";
}) => {
    return (
        <div
            className={cn(
                "w-full px-4 sm:px-10",
                sectionWidth === "CONTAINER" ? "mx-auto max-w-screen-2xl" : "",
                className,
                disabledPadding ?? false ? "sm:px-0 px-0" : "",
            )}
            style={style}>
            <div className={cn(contentWidth === "CONTAINER" ? "mx-auto max-w-screen-2xl" : "")}>{children}</div>
        </div>
    );
};

export default MaxWidthWrapper;
