import * as React from "react"

import { cn } from "@/lib/utils"
import Image from "next/image";

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, ...props }, ref) => {
    const [showPassword, setShowPassword] = React.useState(false);

    const togglePasswordVisibility = () => {
      setShowPassword(!showPassword);
    };

    return (
      <div className="relative">
        <input
          type={showPassword ? "text" : type} 
          className={cn(
            "flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
            className
          )}
          ref={ref}
          {...props}
        />
        {type === "password" && (
          <button
            type="button"
            className="absolute inset-y-0 right-0 flex items-center px-3 focus:outline-none"
            onClick={togglePasswordVisibility}
          >
            <Image
              src={showPassword ? "/assets/icons/eye_hide.png" : "/assets/icons/eye_open.png"}
              alt={showPassword ? "hide" : "show"}
              width={22}
              height={22}
              className="h-[22px] w-[22px]"
            />
          </button>
        )}
      </div>
    )
  }
)
Input.displayName = "Input"

export { Input }
