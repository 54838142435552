import * as React from "react";
import { cva, type VariantProps } from "class-variance-authority";

import { cn } from "@/lib/utils";
import { Loader2 } from "lucide-react";

const buttonVariants = cva(
    "inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 text-[16px] font-normal rounded-full",
    {
        variants: {
            variant: {
                default: "bg-primary text-primary-foreground hover:bg-primary/90",
                destructive:
                    "bg-destructive text-destructive-foreground hover:bg-destructive-hover active:bg-[#D00A08]",
                outline:
                    "border border-input bg-transparent hover:border-c2 hover:text-c2 active:bg-c2 active:text-c2-foreground",
                outlineFull: "border bg-white hover:border-c2 hover:text-c2 active:bg-c2 active:text-c2-foreground",
                secondary: "bg-secondary text-secondary-foreground hover:bg-secondary/80",
                ghost: "hover:bg-accent hover:text-accent-foreground",
                link: "text-primary underline-offset-4 hover:underline",
                c1: "bg-c1 text-c1-foreground hover:bg-c1-hover hover:text-c1-hover-foreground active:bg-c1-active",
                c2: "bg-c2 text-c2-foreground hover:bg-c2-hover hover:text-c2-hover-foreground active:bg-c2-active",
                gradient1:
                    "bg-gradient-to-r from-gradient1-start to-gradient1-end text-c2-foreground hover:bg-gradient-to-r hover:from-c2-hover hover:to-c2-hover active:bg-gradient-to-r active:from-c2-active active:to-c2-active",
                gradient2:
                    "bg-gradient-to-r from-gradient2-start to-gradient2-end text-destructive-foreground hover:from-destructive-hover hover:to-destructive-hover active:from-[#D00A08] active:to-[#D00A08]",

                gradient3:
                    "bg-gradient-to-r from-gradient3-start via-gradient3-via to-gradient3-end    hover:from-gradient3-start hover:via-gradient3-via hover:to-gradient3-end   text-destructive-foreground ",

                gradient4:
                    "bg-gradient-to-r from-gradient4-start via-gradient4-via to-gradient4-end    hover:from-gradient4-start hover:via-gradient4-via hover:to-gradient4-end   text-destructive-foreground ",

                outline_w:
                    "border border-input bg-transparent border-white text-white hover:border-white hover:text-white active:bg-white active:text-white-foreground",
                icon: "bg-grayc4 text-c4 hover:text-c2",
                transparant:"bg-transparant hove:bg-transparant",
                disabled: "bg-grayc4",
            },
            size: {
                default: "h-12 px-4 py-2",
                sm: "h-11 rounded-md px-3",
                lg: "h-10 rounded-md px-8",
                icon: "h-10 w-10",
            },
        },
        defaultVariants: {
            variant: "default",
            size: "default",
        },
    },
);

export interface ButtonProps
    extends React.ButtonHTMLAttributes<HTMLButtonElement>,
        VariantProps<typeof buttonVariants> {
    isLoading?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
    ({ className, children, variant, size, isLoading, ...props }, ref) => {
        return (
            <button
                className={cn(buttonVariants({ variant, size, className }))}
                ref={ref}
                disabled={isLoading}
                {...props}>
                {isLoading ? <Loader2 className="h-4 w-4 animate-spin" /> : null}
                {isLoading && variant === "icon" ? null : children}
            </button>
        );
    },
);
Button.displayName = "Button";

export { Button, buttonVariants };
